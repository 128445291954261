import * as React from 'react';
import { Admin, Resource } from 'react-admin';

// Lists
import { ListNews, CreateNews, EditNews } from './components/Lists/News/news';
import { ListDogs, CreateDog, EditDog } from './components/Lists/Dogs/dogs';
import {
    ListLitters,
    CreateLitter,
    EditLitter,
} from './components/Lists/Litters/litters';
import {
    ListBreeds,
    CreateBreed,
    EditBreed,
} from './components/Lists/Breeds/breeds';
import {
    ListAboutus,
    CreateAboutus,
    EditAboutus,
} from './components/Lists/Aboutus/aboutus';
import {
    ListPedigree,
    CreatePedigree,
    EditPedigree,
} from './components/Lists/Pedigrees/pedigrees';
import {
    ListReprocutors,
    CreateReproductor,
    EditReproductor,
} from './components/Lists/Reproductors/reproductors';

import AuthProvider from './authProvider';
import dataProvider from './dataProvider';

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={AuthProvider}>
        <Resource
            name='news'
            list={ListNews}
            create={CreateNews}
            edit={EditNews}
        />
        <Resource
            name='dogs'
            list={ListDogs}
            create={CreateDog}
            edit={EditDog}
        />
        <Resource
            name='litters'
            list={ListLitters}
            create={CreateLitter}
            edit={EditLitter}
        />
        <Resource
            name='breeds'
            list={ListBreeds}
            create={CreateBreed}
            edit={EditBreed}
        />
        <Resource
            name='aboutus'
            list={ListAboutus}
            create={CreateAboutus}
            edit={EditAboutus}
        />
        <Resource
            name='pedigree'
            list={ListPedigree}
            create={CreatePedigree}
            edit={EditPedigree}
        />
        <Resource
            name='reproductors'
            list={ListReprocutors}
            create={CreateReproductor}
            edit={EditReproductor}
        />
    </Admin>
);
export default App;
