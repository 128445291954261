import React from 'react';
import {
    Create,
    List,
    required,
    TextInput,
    TextField,
    EditButton,
    DeleteButton,
    Datagrid,
    Edit,
    TabbedForm,
    FormTab,
    DateField,
    DateInput,
    SelectInput,
    ImageInput,
    ImageField,
    DateTimeInput,
    ReferenceInput,
    SimpleShowLayout,
} from 'react-admin';
import SimpleArray from '../../../utils/getImages';
import { Box } from '@material-ui/core';

import RichTextInput from 'ra-input-rich-text';

export const CreateDog = (props) => {
    return (
        <Create title='Dodaj nowego psa' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextInput
                        source='name'
                        label='Nazwa psa'
                        validate={[required()]}
                    />

                    <DateInput source='birthDate' label='Data urodzenia' />

                    <SelectInput
                        source='gender'
                        label='Płeć'
                        choices={[
                            { id: 'male', name: 'Pies' },
                            { id: 'female', name: 'Suka' },
                        ]}
                        validate={[required()]}
                    />

                    <SelectInput
                        source='breed'
                        label='Rasa'
                        choices={[
                            {
                                id: 'IRWS',
                                name: 'Seter Irlandzki Czerwono-Biały',
                            },
                            { id: 'ES', name: 'Seter Angielski' },
                            { id: 'MEMORY', name: 'Wspomnienia' },
                        ]}
                        validate={[required()]}
                    />

                    <TextInput source='country' label='Kraj' />

                    <ReferenceInput
                        label='Matka'
                        source='mother_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' />
                    </ReferenceInput>

                    <ReferenceInput
                        label='Ojciec'
                        source='father_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                </FormTab>
                <FormTab label='Język Polski'>
                    <RichTextInput
                        source='pl_description'
                        label='Opis Psa PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='pl_offspring'
                        label='Potomstwo PL'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='pl_achievements'
                        label='Osiągnięcia PL'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <RichTextInput
                        source='en_description'
                        label='Opis Psa EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='en_offspring'
                        label='Potomstwo EN'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='en_achievements'
                        label='Osiągnięcia EN'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <Box flex='1'>
                            <ImageInput
                                source='mainImg'
                                label='Zdjęcie Listy'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='1'>
                            <ImageInput
                                source='pedigree'
                                label='Rodowód'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='1'>
                            <ImageInput
                                source='dogsImg'
                                label='Zdjęcie Psa'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                    </Box>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export const ListDogs = (props) => {
    return (
        <List {...props}>
            <Datagrid rowClick='edit'>
                <TextField source='id' label='id' />
                <TextField source='name' label='Nazwa' />
                <DateField source='birthDate' label='Data urodzenia' />
                <TextField source='gender' label='Płeć' />
                <TextField source='breed' label='Rasa' />
                <EditButton basePath='/news' label='Edytuj' />
                <DeleteButton basePath='/news' label='Usuń' />
            </Datagrid>
        </List>
    );
};

export const EditDog = (props) => {
    return (
        <Edit title="Edytuj news'a" {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextInput source='id' label="ID News'a" disabled />
                    <DateInput source='createdAt' label='Utworzony:' disabled />
                    <DateTimeInput
                        source='updatedAt'
                        label='Edytowany:'
                        disabled
                    />
                    <hr />

                    <TextInput
                        source='name'
                        label='Nazwa psa'
                        validate={[required()]}
                    />
                    <DateInput source='birthDate' label='Data urodzenia' />

                    <SelectInput
                        source='gender'
                        label='Płeć'
                        validate={[required()]}
                        choices={[
                            { id: 'male', name: 'Pies' },
                            { id: 'female', name: 'Suka' },
                        ]}
                    />
                    <SelectInput
                        source='breed'
                        label='Rasa'
                        validate={[required()]}
                        choices={[
                            {
                                id: 'IRWS',
                                name: 'Seter Irlandzki Czerwono-Biały',
                            },
                            { id: 'ES', name: 'Seter Angielski' },
                            { id: 'MEMORY', name: 'Wspomnienia' },
                        ]}
                    />

                    <TextInput source='country' label='Kraj' />

                    <ReferenceInput
                        label='Matka'
                        source='mother_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>

                    <ReferenceInput
                        label='Ojciec'
                        source='father_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                </FormTab>
                <FormTab label='Język Polski'>
                    <RichTextInput
                        source='pl_description'
                        label='Opis Psa PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='pl_offspring'
                        label='Potomstwo PL'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='pl_achievements'
                        label='Osiągnięcia PL'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <RichTextInput
                        source='en_description'
                        label='Opis Psa EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='en_offspring'
                        label='Potomstwo EN'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                    <RichTextInput
                        source='en_achievements'
                        label='Osiągnięcia EN'
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <Box flex='1'>
                            <ImageInput
                                source='mainImg'
                                label='Zdjęcie Listy'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='1'>
                            <ImageInput
                                source='pedigree'
                                label='Rodowód'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='1'>
                            <ImageInput
                                source='dogsImg'
                                label='Zdjęcie Psa'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                    </Box>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <SimpleShowLayout fullWidth>
                        <SimpleArray source='gallery' label='Zdjęcia' />
                    </SimpleShowLayout>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
