import React from 'react';
import {
    Create,
    List,
    ImageInput,
    required,
    TextInput,
    TextField,
    EditButton,
    DeleteButton,
    Datagrid,
    Edit,
    TabbedForm,
    FormTab,
    DateField,
    ImageField,
    Pagination,
    SimpleShowLayout,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import SimpleArray from '../../../utils/getImages';
import { Box } from '@material-ui/core';

//pagination
const BreedPagination = (props) => (
    <Pagination rowsPerPageOptions={[2, 5, 10, 20, 50, 100]} {...props} />
);

export const CreateBreed = (props) => {
    return (
        <Create title='Dodaj nową informację o rasie' {...props}>
            <TabbedForm>
                <FormTab label='Język Polski'>
                    <TextInput
                        source='pl_title'
                        label='Tytuł PL'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='pl_description'
                        label='Treść informacji o rasie PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link', 'image'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <TextInput
                        source='en_title'
                        label='Tytuł EN'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='en_description'
                        label='Treść informacji o rasie EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link', 'image'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <ImageInput
                        source='gallery'
                        label='Galeria'
                        multiple
                        maxSize='1000000'
                    >
                        <ImageField source='src' title='title' />
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export const ListBreeds = (props) => {
    return (
        <List {...props} pagination={<BreedPagination />}>
            <Datagrid rowClick='edit'>
                <TextField source='id' label='id' />
                <TextField source='pl_title' label='Tytuł Polski' />
                <TextField source='en_title' label='Tytuł Angielski' />
                <DateField source='createdAt' label='Data dodania' />
                <DateField source='updatedAt' label='Data edycji' />
                <EditButton basePath='/breeds' label='Edytuj' />
                <DeleteButton basePath='/breeds' label='Usuń' />
            </Datagrid>
        </List>
    );
};

export const EditBreed = (props) => {
    return (
        <Edit title='Edytuj informacje' {...props}>
            <TabbedForm>
                <FormTab label='Język Polski'>
                    <TextInput
                        source='pl_title'
                        label='Tytuł PL'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='pl_description'
                        label='Treść informacji o rasie PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link', 'image'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <TextInput
                        source='en_title'
                        label='Tytuł EN'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='en_description'
                        label='Treść informacji o rasie EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link', 'image'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <SimpleShowLayout fullWidth>
                        <SimpleArray source='gallery' label='Zdjęcia' />
                    </SimpleShowLayout>
                </FormTab>

                <FormTab label='Informacje'>
                    <TextField source='id' label='ID' disabled />
                    <DateField source='createdAt' label='Utworzono' disabled />
                    <DateField source='updatedAt' label='Edytowano' disabled />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
