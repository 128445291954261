import React from 'react';
import {
    Create,
    List,
    ImageInput,
    ImageField,
    required,
    TextInput,
    TextField,
    EditButton,
    DeleteButton,
    Datagrid,
    Edit,
    TabbedForm,
    FormTab,
    DateField,
    Pagination,
    SimpleShowLayout,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import SimpleArray from '../../../utils/getImages';
import { Box } from '@material-ui/core';

//pagination
const RepPagination = (props) => (
    <Pagination rowsPerPageOptions={[2, 5, 10, 20, 50, 100]} {...props} />
);

export const CreateReproductor = (props) => {
    return (
        <Create title='Dodaj nowego reproduktora' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextInput
                        source='name'
                        label='Nazwa psa'
                        validate={[required()]}
                    />
                </FormTab>

                <FormTab label='Język Polski'>
                    <RichTextInput
                        source='pl_description'
                        label='Opis reproduktora PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <RichTextInput
                        source='en_description'
                        label='Opis psreproduktora EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Zdjęcia'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export const ListReprocutors = (props) => {
    return (
        <List {...props} pagination={<RepPagination />}>
            <Datagrid rowClick='edit'>
                <TextField source='id' label='id' />
                <TextField source='name' label='Nazwa psa' />
                <DateField source='createdAt' label='Data dodania' />
                <DateField source='updatedAt' label='Data edycji' />
                <EditButton basePath='/reproductors' label='Edytuj' />
                <DeleteButton basePath='/reproductors' label='Usuń' />
            </Datagrid>
        </List>
    );
};

export const EditReproductor = (props) => {
    return (
        <Edit title='Edytuj Reproduktora' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextField source='id' label='ID' disabled />
                    <DateField source='createdAt' disabled />
                    <DateField source='updatedAt' disabled />
                    <hr></hr>

                    <TextInput source='name' label='Nazwa psa' />
                </FormTab>

                <FormTab label='Język Polski'>
                    <RichTextInput
                        source='pl_description'
                        label='Opis reproduktora PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <RichTextInput
                        source='en_description'
                        label='opis reproduktora EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>

                    <SimpleShowLayout fullWidth>
                        <SimpleArray source='gallery' label='Zdjęcia' />
                    </SimpleShowLayout>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
