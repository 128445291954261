import axios from 'axios';
import './style.css';

async function RemoveImg(id) {
    if (
        window.confirm('Czy na pewno chcesz usunąć zdjęcie o ID: ' + id + '?')
    ) {
        await axios
            .delete(`http://api.shadowdog.eu/v1/images/${id}`)
            .then(window.location.reload());
    } else {
        console.log('Error: błąd podczas usuwnaia zdjęcia!');
    }
}

function ImagesList(gallery) {
    console.log(gallery);
    return gallery.map((im) => (
        <div className='image' key={im.id}>
            <div className='imageDelete' onClick={() => RemoveImg(im.id)}>
                X
            </div>

            <img src={im.pathurl} alt='' key={im.id} className='img' />
        </div>
    ));
}

const SimpleArray = ({ source, record = {} }) => (
    <div className='imagesContainer'>{ImagesList(record[source])}</div>
);

export default SimpleArray;
