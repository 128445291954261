import {fetchUtils} from 'react-admin';
import restServerProvider from 'ra-data-json-server';
import getDataToProvider from '../src/utils/dataToProvider';

const servicesHost = 'https://api.shadowdog.eu/v1';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('auth-token');
    // add your own headers here
    options.headers.set('auth-token', token);
    return fetchUtils.fetchJson(url, options);
};

const dataRestProvider = restServerProvider(servicesHost, httpClient);

const myDataProvider = {
    ...dataRestProvider,
    create: (resource, params) => {

        let formData = new FormData();

        switch(resource){
            case "news": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);
                formData.append('isGlobal', params.data.isGlobal);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "dogs": {
                formData.append('name', params.data.name);
                formData.append('breed', params.data.breed);
                formData.append('gender', params.data.gender);
                formData.append('country', params.data.country);
                formData.append('birthDate', params.data.birthDate);
                formData.append('mother_ID', params.data.mother_ID);
                formData.append('father_ID', params.data.father_ID);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);
                formData.append('pl_results', params.data.pl_results);
                formData.append('en_results', params.data.en_results);
                formData.append('pl_offspring', params.data.pl_offspring);
                formData.append('en_offspring', params.data.en_offspring);
                formData.append('pl_achievements', params.data.pl_achievements);
                formData.append('en_achievements', params.data.en_achievements);

                //images
                if(params.data.mainImg){
                    formData.append('mainImg', params.data.mainImg.rawFile);
                }
                if(params.data.dogsImg){
                    formData.append('dogsImg', params.data.dogsImg.rawFile);
                }
                if(params.data.pedigree){
                    formData.append('pedigree', params.data.pedigree.rawFile);
                }
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "litters": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('breed', params.data.breed);
                formData.append('birthDate', params.data.birthDate);
                formData.append('mother_ID', params.data.mother_ID);
                formData.append('father_ID', params.data.father_ID);
                formData.append('fatherID', params.data.fatherID);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);
                formData.append('pedigree', params.data.pedigree);

                //images
                if(params.data.mainImg){
                    formData.append('mainImg', params.data.mainImg.rawFile);
                }
                if(params.data.parentImg){
                    formData.append('parentImg', params.data.parentImg.rawFile);
                }
                if(params.data.cardsImg){
                    params.data.cardsImg.forEach((item) => {
                        formData.append('cardsImg', item.rawFile);
                    });
                }
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "aboutus": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "breeds": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "pedigree": {
                formData.append('name', params.data.name);
                formData.append('gender', params.data.gender);
                formData.append('breed', params.data.breed);
                formData.append('kennel', params.data.kennel);
                formData.append('birthDate', params.data.birthDate);
                formData.append('country', params.data.country);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "reproductors": {
                formData.append('name', params.data.name);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            default: {
                break;
            }
        }

        return httpClient(`${servicesHost}/${resource}`, {
            method: 'POST',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        }));
    },
    update: (resource, params) => {

        let formData = new FormData();

        switch(resource){
            case "news": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);
                formData.append('isGlobal', params.data.isGlobal);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "dogs": {
                formData.append('name', params.data.name);
                formData.append('breed', params.data.breed);
                formData.append('gender', params.data.gender);
                formData.append('country', params.data.country);
                formData.append('birthDate', params.data.birthDate);
                formData.append('mother_ID', params.data.mother_ID);
                formData.append('father_ID', params.data.father_ID);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);
                formData.append('pl_results', params.data.pl_results);
                formData.append('en_results', params.data.en_results);
                formData.append('pl_offspring', params.data.pl_offspring);
                formData.append('en_offspring', params.data.en_offspring);
                formData.append('pl_achievements', params.data.pl_achievements);
                formData.append('en_achievements', params.data.en_achievements);

                //images
                if(params.data.mainImg){
                    formData.append('mainImg', params.data.mainImg.rawFile);
                }
                if(params.data.dogsImg){
                    formData.append('dogsImg', params.data.dogsImg.rawFile);
                }
                if(params.data.pedigree){
                    formData.append('pedigree', params.data.pedigree.rawFile);
                }
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "litters": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('breed', params.data.breed);
                formData.append('birthDate', params.data.birthDate);
                formData.append('mother_ID', params.data.mother_ID);
                formData.append('father_ID', params.data.father_ID);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);
                formData.append('pedigree', params.data.pedigree);

                //images
                if(params.data.mainImg){
                    formData.append('mainImg', params.data.mainImg.rawFile);
                }
                if(params.data.parentImg){
                    formData.append('parentImg', params.data.parentImg.rawFile);
                }
                if(params.data.cardsImg){
                    params.data.cardsImg.forEach((item) => {
                        formData.append('cardsImg', item.rawFile);
                    });
                }
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "aboutus": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "breeds": {
                formData.append('pl_title', params.data.pl_title);
                formData.append('en_title', params.data.en_title);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "pedigree": {
                formData.append('name', params.data.name);
                formData.append('gender', params.data.gender);
                formData.append('breed', params.data.breed);
                formData.append('kennel', params.data.kennel);
                formData.append('birthDate', params.data.birthDate);
                formData.append('country', params.data.country);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            case "reproductors": {
                formData.append('name', params.data.name);
                formData.append('pl_description', params.data.pl_description);
                formData.append('en_description', params.data.en_description);

                //images
                if(params.data.gallery){
                    params.data.gallery.forEach((item) => {
                        formData.append('gallery', item.rawFile);
                    });
                }
                break;
            }
            default: {
                break;
            }
        }
        return httpClient(`${servicesHost}/${resource}/${params.id}`, {
            method: 'PUT',
            body: formData,
        }).then(({ json }) => ({
            data: { ...params.data },
        }));
        },
};

export default myDataProvider;