import React from 'react';
import {
    Create,
    List,
    ImageInput,
    required,
    TextInput,
    TextField,
    EditButton,
    DeleteButton,
    Datagrid,
    Edit,
    TabbedForm,
    FormTab,
    DateField,
    DateInput,
    Pagination,
    SelectInput,
    ImageField,
    SimpleShowLayout,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import SimpleArray from '../../../utils/getImages';
import { Box } from '@material-ui/core';

//pagination
const PedigreePagination = (props) => (
    <Pagination rowsPerPageOptions={[2, 5, 10, 20, 50, 100]} {...props} />
);

export const CreatePedigree = (props) => {
    return (
        <Create title='Dodaj nowego psa do rodowodu' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextInput
                        source='name'
                        label='Nazwa psa'
                        validate={[required()]}
                    />
                    <SelectInput
                        source='breed'
                        label='Rasa'
                        choices={[
                            {
                                id: 'IRWS',
                                name: 'Seter Irlandzki Czerwono-Biały',
                            },
                            { id: 'ES', name: 'Seter Angielski' },
                        ]}
                        validate={[required()]}
                    />
                    <SelectInput
                        source='gender'
                        label='Płeć'
                        choices={[
                            { id: 'male', name: 'Pies' },
                            { id: 'female', name: 'Suka' },
                        ]}
                        validate={[required()]}
                    />

                    <DateInput source='birthDate' label='Data urodzenia' />
                    <TextInput source='country' label='Kraj pochodzenia' />
                    <TextInput source='kennel' label='Nazwa hodowli' />
                </FormTab>

                <FormTab label='Język Polski'>
                    <RichTextInput
                        source='pl_description'
                        label='Opis psa'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <RichTextInput
                        source='en_description'
                        label='Opis psa'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Zdjęcia'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Zdjęcie psa'
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export const ListPedigree = (props) => {
    return (
        <List {...props} pagination={<PedigreePagination />}>
            <Datagrid rowClick='edit'>
                <TextField source='id' label='id' />
                <TextField source='name' label='Nazwa psa' />
                <TextField source='gender' label='Płeć' />
                <TextField source='breed' label='Rasa' />
                <TextField source='country' label='Kraj' />
                <TextField source='kennel' label='Hodowla' />
                <DateField source='createdAt' label='Data dodania' />
                <DateField source='updatedAt' label='Data edycji' />
                <EditButton basePath='/pedigree' label='Edytuj' />
                <DeleteButton basePath='/pedigree' label='Usuń' />
            </Datagrid>
        </List>
    );
};

export const EditPedigree = (props) => {
    return (
        <Edit title='Edytuj psa z dodowodu' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextInput source='id' label='ID' disabled />
                    <DateInput source='createdAt' label='Utworzono' disabled />
                    <DateInput source='updatedAt' label='Edytowano' disabled />
                    <hr></hr>

                    <TextInput source='name' label='Nazwa psa' />
                    <SelectInput
                        source='breed'
                        label='Rasa'
                        choices={[
                            {
                                id: 'IRWS',
                                name: 'Seter Irlandzki Czerwono-Biały',
                            },
                            { id: 'ES', name: 'Seter Angielski' },
                        ]}
                        validate={[required()]}
                    />
                    <SelectInput
                        source='gender'
                        label='Płeć'
                        choices={[
                            { id: 'male', name: 'Pies' },
                            { id: 'female', name: 'Suka' },
                        ]}
                        validate={[required()]}
                    />
                    <DateInput source='birthDate' label='Data urodzenia' />
                    <TextInput source='country' label='Kraj pochodzenia' />
                    <TextInput source='kennel' label='Nazwa Hodowli' />
                </FormTab>

                <FormTab label='Język Polski'>
                    <RichTextInput
                        source='pl_description'
                        label='Opis psa PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <RichTextInput
                        source='en_description'
                        label='opis psa EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Zdjęcie psa'
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>

                    <SimpleShowLayout fullWidth>
                        <SimpleArray source='gallery' label='Zdjęcia' />
                    </SimpleShowLayout>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
