import React from 'react';
import {
    Create,
    List,
    ImageInput,
    required,
    TextInput,
    TextField,
    EditButton,
    DeleteButton,
    Datagrid,
    Edit,
    TabbedForm,
    FormTab,
    DateField,
    ImageField,
    Pagination,
    SelectInput,
    ReferenceInput,
    DateInput,
    SimpleShowLayout,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import SimpleArray from '../../../utils/getImages';
import { Box } from '@material-ui/core';

//pagination
const AboutusPagination = (props) => (
    <Pagination rowsPerPageOptions={[2, 5, 10, 20, 50, 100]} {...props} />
);

export const CreateLitter = (props) => {
    return (
        <Create title='Dodaj nowy miot' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <SelectInput
                        source='breed'
                        label='Rasa'
                        choices={[
                            {
                                id: 'IRWS',
                                name: 'Seter Irlandzki Czerwono-Biały',
                            },
                            { id: 'ES', name: 'Seter Angielski' },
                        ]}
                        validate={[required()]}
                    />

                    <DateInput
                        source='birthDate'
                        label='Data urodzenia'
                        validate={[required()]}
                    />

                    <ReferenceInput
                        label='Matka'
                        source='mother_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>

                    <ReferenceInput
                        label='Ojciec'
                        source='father_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                </FormTab>

                <FormTab label='Język Polski'>
                    <TextInput
                        source='pl_title'
                        label='Tytuł miotu po Polsku'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='pl_description'
                        label='Treść informacji o miocie'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <TextInput
                        source='en_title'
                        label='Tytuł miotu po Angielsku'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='en_description'
                        label='Treść informacji o miocie'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <Box flex='1' mx='10px'>
                            <ImageInput
                                source='mainImg'
                                label='Zdjęcie Listy'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='1' mx='10px'>
                            <ImageInput
                                source='parentImg'
                                label='Zdjęcie rodziców'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                    </Box>
                    <Box display='flex' fullWidth>
                        <Box flex='1' mx='10px'>
                            <ImageInput
                                source='cardsImg'
                                label='Zapowiedzi / kartki'
                                multiple
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='2' mx='10px'>
                            <ImageInput
                                source='gallery'
                                label='Galeria'
                                multiple
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                    </Box>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export const ListLitters = (props) => {
    return (
        <List {...props} pagination={<AboutusPagination />}>
            <Datagrid rowClick='edit'>
                <TextField source='id' label='id' />
                <TextField source='pl_title' label='Tytuł Polski' />
                <TextField source='en_title' label='Tytuł Angielski' />
                <DateField source='createdAt' label='Data dodania' />
                <DateField source='updatedAt' label='Data edycji' />
                <EditButton basePath='/litters' label='Edytuj' />
                <DeleteButton basePath='/litters' label='Usuń' />
            </Datagrid>
        </List>
    );
};

export const EditLitter = (props) => {
    return (
        <Edit title='Edytuj informacje' {...props}>
            <TabbedForm>
                <FormTab label='Informacje'>
                    <TextField source='id' label='ID' disabled />
                    <DateField source='createdAt' label='Utworzono' disabled />
                    <DateField source='updatedAt' label='Edytowano' disabled />
                    <hr></hr>

                    <SelectInput
                        source='breed'
                        label='Rasa'
                        choices={[
                            {
                                id: 'IRWS',
                                name: 'Seter Irlandzki Czerwono-Biały',
                            },
                            { id: 'ES', name: 'Seter Angielski' },
                        ]}
                        validate={[required()]}
                    />

                    <DateInput
                        source='birthDate'
                        label='Data urodzenia'
                        validate={[required()]}
                    />

                    <ReferenceInput
                        label='Matka'
                        source='mother_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>

                    <ReferenceInput
                        label='Ojciec'
                        source='father_ID'
                        reference='pedigree'
                    >
                        <SelectInput optionText='name' optionValue='id' />
                    </ReferenceInput>
                </FormTab>

                <FormTab label='Język Polski'>
                    <TextInput
                        source='pl_title'
                        label='Nazwa miotu po Polsku'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='pl_description'
                        label='Treść informacji o miocie'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <TextInput
                        source='en_title'
                        label='Nazwa miotu po Angielsku'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='en_description'
                        label='Treść informacji o miocie'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <Box flex='1' mx='10px'>
                            <ImageInput
                                source='mainImg'
                                label='Zdjęcie Listy'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='1' mx='10px'>
                            <ImageInput
                                source='parentImg'
                                label='Zdjęcie rodziców'
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                    </Box>
                    <Box display='flex' fullWidth>
                        <Box flex='1' mx='10px'>
                            <ImageInput
                                source='cardsImg'
                                label='Zapowiedzi / kartki'
                                multiple
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                        <Box flex='2' mx='10px'>
                            <ImageInput
                                source='gallery'
                                label='Galeria'
                                multiple
                                maxSize='1000000'
                                fullWidth
                            >
                                <ImageField source='src' title='title' />
                            </ImageInput>
                        </Box>
                    </Box>
                    <SimpleShowLayout fullWidth>
                        <SimpleArray source='gallery' label='Zdjęcia' />
                    </SimpleShowLayout>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
