import React from 'react';
import {
    Create,
    List,
    ImageInput,
    required,
    TextInput,
    TextField,
    EditButton,
    DeleteButton,
    Datagrid,
    Edit,
    TabbedForm,
    FormTab,
    BooleanInput,
    ChipField,
    DateField,
    DateInput,
    Pagination,
    ImageField,
    SimpleShowLayout,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Box } from '@material-ui/core';
import SimpleArray from '../../../utils/getImages';

//pagination
const NewsPagination = (props) => (
    <Pagination rowsPerPageOptions={[2, 5, 10, 20, 50, 100]} {...props} />
);

export const CreateNews = (props) => {
    return (
        <Create title="Dodaj nowego news'a" {...props}>
            <TabbedForm>
                <FormTab label='Język Polski'>
                    <TextInput
                        source='pl_title'
                        label='Tytuł PL'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='pl_description'
                        label='Treść Newsa PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <TextInput
                        source='en_title'
                        label='Tytuł EN'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='en_description'
                        label='Treść Newsa EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                </FormTab>

                <FormTab label='Informacje'>
                    <BooleanInput source='isGlobal' label='News Globalny' />
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export const ListNews = (props) => {
    return (
        <List {...props} pagination={<NewsPagination />}>
            <Datagrid rowClick='edit'>
                <TextField source='id' label='id' />
                <ChipField source='isGlobal' label='Globalny' />
                <TextField source='pl_title' label='Tytuł PL' />
                <TextField source='en_title' label='Tytuł EN' />
                <DateField source='createdAt' label='Data dodania' />
                <DateField source='updatedAt' label='Data edycji' />
                <EditButton basePath='/news' label='Edytuj' />
                <DeleteButton basePath='/news' label='Usuń' />
            </Datagrid>
        </List>
    );
};

export const EditNews = (props) => {
    return (
        <Edit title="Edytuj news'a" {...props}>
            <TabbedForm>
                <FormTab label='Język Polski'>
                    <TextInput
                        source='pl_title'
                        label='Tytuł PL'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='pl_description'
                        label='Treść Newsa PL'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Język Angielski'>
                    <TextInput
                        source='en_title'
                        label='Tytuł EN'
                        validate={[required()]}
                    />
                    <RichTextInput
                        source='en_description'
                        label='Treść Newsa EN'
                        validate={[required()]}
                        toolbar={[
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            [{ color: [] }, { background: [] }],
                            [{ align: [] }],
                            ['link'],
                            ['clean'],
                        ]}
                    />
                </FormTab>

                <FormTab label='Galeria'>
                    <Box display='flex' fullWidth>
                        <ImageInput
                            source='gallery'
                            label='Galeria'
                            multiple
                            maxSize='1000000'
                            fullWidth
                        >
                            <ImageField source='src' title='title' />
                        </ImageInput>
                    </Box>
                    <SimpleShowLayout fullWidth>
                        <SimpleArray source='gallery' label='Zdjęcia' />
                    </SimpleShowLayout>
                </FormTab>

                <FormTab label='Informacje'>
                    <TextInput source='id' label="ID News'a" disabled />
                    <DateInput source='createdAt' label='Utworzony:' disabled />
                    <DateInput source='updatedAt' label='Edytowany:' disabled />
                    <BooleanInput source='isGlobal' label='News Globalny' />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};
